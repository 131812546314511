import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from './Layout';
import { Section } from '~/components/elements/Section';
import { LayoutRenderer } from '~/components/layouts/LayoutRenderer';

import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function DefaultPage({ data: { wpPage, wp } }) {
  const footerBlock = wp.footer?.globalFooter?.footer?.defaultPageCta?.layouts;
  console.log(wpPage);
  return (
    <Layout wp={wp} page={wpPage}>
      {wpPage && <Seo post={wpPage} />}
      <LayoutRenderer prefix="Page_Layouts_Sections_Section_Layout_" layouts={wpPage?.layouts} />
      {footerBlock && !wpPage?.postTypePage?.hideDefaultCta && (
        <LayoutRenderer prefix="ReusableBlock_Layouts_Sections_Section_Layout_" layouts={footerBlock} />
      )}
    </Layout>
  );
}

export const pageQuery = graphql`
  query Default($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
  }
`;
